import { createContext, FC, ReactNode, useState } from 'react';

interface IProps {
    children: ReactNode;
}

export const JobsContext = createContext<{
    fetchSchedulesAndWarnings: boolean;
    setFetchSchedulesAndWarnings: (state: boolean) => void;
    refreshAssignedJobs: boolean;
    setRefreshAssignedJobs: (state: boolean) => void;
    refreshUnassignedJobs: boolean;
    setRefreshUnassignedJobs: (state: boolean) => void;
    refreshPatients: boolean;
    setRefreshPatients: (state: boolean) => void;
    jobSummaryHcp?: string;
    jobSummaryJob?: string;
    setJobSummary: (jobId?: string, hcpId?: string) => void;
}>({
    fetchSchedulesAndWarnings: false,
    setFetchSchedulesAndWarnings: () => {},
    refreshAssignedJobs: false,
    setRefreshAssignedJobs: () => {},
    refreshUnassignedJobs: false,
    setRefreshUnassignedJobs: () => {},
    refreshPatients: false,
    setRefreshPatients: () => {},
    jobSummaryHcp: undefined,
    jobSummaryJob: undefined,
    setJobSummary: () => {},
});

export const JobsProvider: FC<IProps> = ({ children }) => {
    const [fetchSchedulesAndWarnings, setFetchSchedulesAndWarnings] = useState(false);
    const [refreshAssignedJobs, setRefreshAssignedJobs] = useState(false);
    const [refreshUnassignedJobs, setRefreshUnassignedJobs] = useState(false);
    const [refreshPatients, setRefreshPatients] = useState(false);
    const [jobSummaryHcp, setJobSummaryHcp] = useState<string>();
    const [jobSummaryJob, setJobSummaryJob] = useState<string>();

    const setJobSummary = (jobId?: string, hcpId?: string) => {
        setJobSummaryHcp(hcpId);
        setJobSummaryJob(jobId);
    };

    const value = {
        fetchSchedulesAndWarnings,
        setFetchSchedulesAndWarnings,
        refreshAssignedJobs,
        setRefreshAssignedJobs,
        refreshUnassignedJobs,
        setRefreshUnassignedJobs,
        refreshPatients,
        setRefreshPatients,
        jobSummaryHcp,
        jobSummaryJob,
        setJobSummary,
    };

    return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>;
};
