import { AlertStatus, Schedule, StaffAlert } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

import { isUserSick } from '../../../../../helpers/ucr/isUserSick';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

interface SwimLaneProps {
    style: React.CSSProperties;
    schedules: Schedule[];
    hourStart: number;
    cellWidth: number;
    userIsSick: boolean;
    userHasOpenAlert: boolean;
}

const SwimLane: FC<SwimLaneProps> = ({
    style,
    schedules,
    hourStart,
    cellWidth,
    userIsSick,
    userHasOpenAlert,
}: SwimLaneProps) => {
    let background = 'transparent';

    if (userHasOpenAlert) {
        background = 'rgba(255, 0, 0, 0.2)'; // Red tint
    } else if (userIsSick) {
        background = 'rgba(255, 177, 0, 0.1)'; // Yellow tint
    }

    // The CSS gradient draws the horizontal grid lines
    const containerStyle = {
        backgroundImage: `linear-gradient(to right, #ccc 1px, ${background} 1px)`,
        backgroundSize: `${cellWidth}px 100%`,
    };

    return (
        <div
            className="ucr__calendar-swimlane"
            style={{ ...style, ...containerStyle }}
            data-testid="swim-lane"
        >
            {schedules.map((schedule) => {
                const startTime = moment(schedule.startDateTime);
                const endTime = moment(schedule.endDateTime);
                const startHour = startTime.hour() + startTime.minute() / 60;
                const endHour = endTime.hour() + endTime.minute() / 60;

                return (
                    <div
                        key={schedule.scheduleId}
                        className="ucr__calendar-swimlane-schedule-block"
                        style={{
                            left: `${(startHour - hourStart) * cellWidth}px`,
                            width: `${(endHour - startHour) * cellWidth}px`,
                        }}
                        data-testid="schedule-block"
                    />
                );
            })}
        </div>
    );
};

const TimeGrid: FC = () => {
    const listRef = useRef<VariableSizeList>(null);

    const {
        RootStore: {
            ucrStore: { cellWidth, hourStart, hcpsPos, staffAlerts },
            schedulesStore: { allSchedules },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    const hcpsKeys = Object.keys(hcpsPos);
    const height = hcpsKeys.reduce((acc, key) => acc + hcpsPos[key]?.height, 0);

    const getRowHeight = (index: number) => {
        const key = hcpsKeys[index];
        const height = hcpsPos[key]?.height;
        return height;
    };

    // Force the list to re-render when hcpsPos changes
    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0, true);
        }
    }, [hcpsPos]);

    return (
        <div className="ucr__calendar-time-grid" data-testid="time-grid">
            <VariableSizeList
                ref={listRef}
                height={height}
                itemCount={hcpsKeys.length}
                itemSize={getRowHeight}
                width="100%"
            >
                {({ index, style }) => {
                    const userId = hcpsKeys[index];
                    const userIsSick = isUserSick(userId, users);

                    const userHasOpenAlert = staffAlerts.some(
                        (alert: StaffAlert) =>
                            alert.status === AlertStatus.OPEN && alert.userId === userId,
                    );

                    const schedulesForUser = allSchedules.filter(
                        (schedule: Schedule) => schedule.userId === userId,
                    );

                    return (
                        <SwimLane
                            style={style}
                            schedules={schedulesForUser}
                            hourStart={hourStart}
                            cellWidth={cellWidth}
                            userIsSick={userIsSick}
                            userHasOpenAlert={userHasOpenAlert}
                            key={userId}
                        />
                    );
                }}
            </VariableSizeList>
        </div>
    );
};

export default observer(TimeGrid);
